import React, { useState, useEffect, useContext } from "react"
import { Alert, Container, Row, Col, Form, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link, navigate } from "gatsby"
import api from "../utils/api"
import LoadingOverlay from "react-loading-overlay"
import Seo from "../components/seo"
import styled from "styled-components"
import Modal from "../components/home/modal"
import Layout from "../components/layout"


export default function UploadFile() {
  const isBrowser = typeof window !== "undefined"
  const user = isBrowser ? JSON.parse(window.localStorage.getItem("user")) : {}
  const distributorId = "638295f484f4e184b8111617"
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileUrl, setFileUrl] = useState("")

  const handleChangeFile = event => {
    setSelectedFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
    setIsFilePicked(true)
  }

  const uploadFile = async () => {
    try {
      const formData = new FormData()

      formData.append("file", selectedFile)
      formData.append("distributorId", distributorId)
      formData.append("userId", user.id)

      setLoading(true)
      await api().post(`/uploads`, formData)
      setLoading(false)
      setSuccess("Uploaded file")

      setSelectedFile({})
      setIsFilePicked(false)
      setFileUrl("")
    } catch (err) {
      const { message } = err.response.data
      setLoading(false)
      setError(message)
    }
  }

  return (
    <Layout>
      <div style={{ width: "300px", margin: "auto", marginTop: "2rem", height: "50vh" }}>
        <Modal />
        <Form className="mx-2">
          <Form.Group>
            <Form.Label>
              <h5 className="text-muted pt-2">Upload order list</h5>
            </Form.Label>
            {error ? <Alert variant="danger">{error}</Alert> : ""}
            {success ? <Alert variant="success">{success}</Alert> : ""}
            <LoadingOverlay active={loading} spinner text="">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                  type="file"
                  onChange={e => {
                    handleChangeFile(e)
                  }}
                />
              </Form.Group>

              <RequestCallBackBtn
                onClick={e => {
                  uploadFile()
                }}
              >
                Upload
              </RequestCallBackBtn>
            </LoadingOverlay>
          </Form.Group>
        </Form>
        <div>Update your Phone Number for quick feedback <a href={"/profile"}>here</a> or under your profile</div>
      </div>
    </Layout>
  )
}

const RequestCallBackBtn = styled.div`
  background: #03989e;
  padding: 1rem 4rem;
  margin-top: 1rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
`
